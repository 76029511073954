import React from "react";
import Layout from "../../Layout";
import "./style.scss";

// import SEO from "../../seo";

export default function Annexure13() {
  return (
    <Layout>
      <div className="pdfview">
        <h1 className="heading">
          Annexure-13 - Last Three Years Result of the Board Examination
        </h1>

        <div
          type="application/pdf"
          width="100%"
          height="100%"
          className="pdfview__container"
        >
          <object
            type="application/pdf"
            width="100%"
            height="100%"
            className=""
            data="https://chinmayavvdelhi.ac.in/assets/pdfs/appendix/2023/Annexure-13-Last-Three-Years-Result-of-the-Board-Examination.pdf"
          ></object>
        </div>
      </div>
    </Layout>
  );
}
